import React from 'react';
import { _Object } from 'utils/interfaces';

interface DropdownOption {
	label: string;
	value: string;
}

interface DropdownFilterProps {
	title: string;
	options: DropdownOption[];
	filterKey: string;
	filterData: _Object;
	setFilterData: React.Dispatch<React.SetStateAction<{ filter: { [key: string]: string } }>>;
}

const DropdownFilter: React.FC<DropdownFilterProps> = ({ title, options, filterKey, filterData, setFilterData }) => {
	return (
		<th style={{ width: '100px' }} className="dropdown">
			<button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
				{title}
			</button>
			<div className="dropdown-menu">
				{options.map((option) => (
					<button
						key={option.value}
						type="button"
						className={`dropdown-item ${filterData.filter?.[filterKey] === option.value ? 'active' : ''}`}
						onClick={() =>
							setFilterData((prev) => ({
								...prev,
								filter: {
									...prev.filter,
									[filterKey]: prev.filter?.[filterKey] === option.value ? '' : option.value
								}
							}))
						}
					>
						{option.label}
					</button>
				))}
			</div>
		</th>
	);
};

export default DropdownFilter;
